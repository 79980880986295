import { render, staticRenderFns } from "./car.vue?vue&type=template&id=72951d94&scoped=true"
import script from "./car.vue?vue&type=script&lang=js"
export * from "./car.vue?vue&type=script&lang=js"
import style0 from "./car.vue?vue&type=style&index=0&id=72951d94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72951d94",
  null
  
)

export default component.exports