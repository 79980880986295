<template>
    <div class="carContent">
        <el-form
            class="ruleform"
            :model="ruleForm"
            ref="ruleForm"
            label-position="right"
            label-width="0px"
        >   
            <span class="rule" @click="DialogObj.dialogVisible = true">查看车牌号编码规则</span>
            <div class="delContent">
                <div class="contant">
                    <span v-for="(item,index) in ruleForm.one" :key="index">
                        <el-form-item class="el-form-itemNew" v-if="index ==0">
                            <input type="text" v-model="item.value" class="input-width-smallNew one" @click="getCity('one',index)" 
                            @keyup="keyboard($event, index,'one')" @keydown="expurgate($event, index,'one')" maxlength="1" :readonly="true">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index == 1">
                            <input type="text" v-model="item.value" class="input-width-smallNew one" @click="getLetter('one',index)" 
                            @keyup="keyboard($event, index,'one')" @keydown="expurgate($event, index,'one')" maxlength="1" :readonly="true">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index !==0 && index !==1 && index !==7">
                            <input type="text" v-model="item.value" class="input-width-smallNew one" @keyup="keyboard($event, index,'one')"
                            @keydown="expurgate($event, index,'one')" maxlength="1" @focus="foucusInput('one')" >
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index == 7">
                            <input type="text" v-model="item.value" class="input-width-smallNew one" @keyup="keyboard($event, index,'one')"
                            @keydown="expurgate($event, index,'one')" maxlength="1" @focus="foucusInput('one')" placeholder="新" style="background-color: #adf0bc">
                            
                        </el-form-item>
                    </span>
                </div>
                <img src="@/assets/images/car/del.png" v-if="statusList.one.delStatus == true" alt="" @click="clear('one')">
                <img src="@/assets/images/car/del-dis.png" v-else alt="" >
                <p class="error" v-if="statusList.one.isSubmit == false">车牌号格式不正确</p>
                <div class="province" v-show="statusList.one.showCity == true">
                    <span v-for="(item,index) in cityList" :key="index" @click="selecCity(item,'one')" :class="active == item ? 'active' : ''">{{ item }}</span>
                </div>
                <!-- 字母弹窗 -->
                <div class="province letter" v-show="statusList.one.showLetter == true">
                    <span v-for="(item,index) in letterList" :key="index" @click="selecLetter(item,'one')" :class="active == item ? 'active' : ''">{{ item }}</span>
                </div>
            </div>
            <div class="delContent">
                <div class="contant">
                    <span v-for="(item,index) in ruleForm.two" :key="index">
                        <el-form-item class="el-form-itemNew" v-if="index ==0">
                            <input type="text" v-model="item.value" class="input-width-smallNew two" @click="getCity('two',index)" 
                            @keyup="keyboard($event, index,'two')" @keydown="expurgate($event, index,'two')" maxlength="1" :readonly="true">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index == 1">
                            <input type="text" v-model="item.value" class="input-width-smallNew two" @click="getLetter('two',index)" 
                            @keyup="keyboard($event, index,'two')" @keydown="expurgate($event, index,'two')" maxlength="1" :readonly="true">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index !==0 && index !==1 && index !==7">
                            <input type="text" v-model="item.value" class="input-width-smallNew two" @keyup="keyboard($event, index,'two')" 
                            @keydown="expurgate($event, index,'two')" @focus="foucusInput('two')" maxlength="1">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index == 7">
                            <input type="text" v-model="item.value" class="input-width-smallNew two" @keyup="keyboard($event, index,'two')"
                            @keydown="expurgate($event, index,'two')" maxlength="1" @focus="foucusInput('two')" placeholder="新" style="background-color: #adf0bc">
                            
                        </el-form-item>
                    </span>
                </div>
                <img src="@/assets/images/car/del.png" v-if="statusList.two.delStatus == true" alt="" @click="clear('two')">
                <img src="@/assets/images/car/del-dis.png" v-else alt="" >
                <p class="error" v-if="statusList.two.isSubmit == false">车牌号格式不正确</p>
                <div class="province" v-show="statusList.two.showCity == true">
                    <span v-for="(item,index) in cityList" :key="index" @click="selecCity(item,'two')" :class="active == item ? 'active' : ''">{{ item }}</span>
                </div>
                <!-- 字母弹窗 -->
                <div class="province letter" v-show="statusList.two.showLetter == true">
                    <span v-for="(item,index) in letterList" :key="index" @click="selecLetter(item,'two')" :class="active == item ? 'active' : ''">{{ item }}</span>
                </div>
            </div>
            <div class="delContent">
                <div class="contant">
                    <span v-for="(item,index) in ruleForm.three" :key="index">
                        <el-form-item class="el-form-itemNew" v-if="index ==0">
                            <input type="text" v-model="item.value" class="input-width-smallNew three" @click="getCity('three',index)" 
                            @keyup="keyboard($event, index,'three')" @keydown="expurgate($event, index,'three')" maxlength="1" :readonly="true">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index == 1">
                            <input type="text" v-model="item.value" class="input-width-smallNew three" @click="getLetter('three',index)" 
                            @keyup="keyboard($event, index,'three')" @keydown="expurgate($event, index,'three')" maxlength="1" :readonly="true">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index !==0 && index !==1 && index !==7">
                            <input type="text" v-model="item.value" class="input-width-smallNew three" @keyup="keyboard($event, index,'three')" 
                            @keydown="expurgate($event, index,'three')" @focus="foucusInput('three')" maxlength="1">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew " v-if="index == 7" >
                            <input type="text" v-model="item.value" class="input-width-smallNew three" @keyup="keyboard($event, index,'three')"
                            @keydown="expurgate($event, index,'three')" maxlength="1" @focus="foucusInput('three')" placeholder="新" style="background-color: #adf0bc">
                            
                        </el-form-item>
                    </span>
                </div>
                <img src="@/assets/images/car/del.png" v-if="statusList.three.delStatus == true" alt="" @click="clear('three')">
                <img src="@/assets/images/car/del-dis.png" v-else alt="" >
                <p class="error" v-if="statusList.three.isSubmit == false">车牌号格式不正确</p>
                <div class="province" v-show="statusList.three.showCity == true">
                    <span v-for="(item,index) in cityList" :key="index" @click="selecCity(item,'three')" :class="active == item ? 'active' : ''">{{ item }}</span>
                </div>
                <!-- 字母弹窗 -->
                <div class="province letter" v-show="statusList.three.showLetter == true">
                    <span v-for="(item,index) in letterList" :key="index" @click="selecLetter(item,'three')" :class="active == item ? 'active' : ''">{{ item }}</span>
                </div>
            </div>
            <div class="delContent">
                <div class="contant">
                    <span v-for="(item,index) in ruleForm.four" :key="index">
                        <el-form-item class="el-form-itemNew" v-if="index ==0">
                            <input type="text" v-model="item.value" class="input-width-smallNew four" @click="getCity('four',index)" 
                            @keyup="keyboard($event, index,'four')" @keydown="expurgate($event, index,'four')" maxlength="1" :readonly="true">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index == 1">
                            <input type="text" v-model="item.value" class="input-width-smallNew four" @click="getLetter('four',index)" 
                            @keyup="keyboard($event, index,'four')" @keydown="expurgate($event, index,'four')" maxlength="1" :readonly="true">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index !==0 && index !==1 && index !==7">
                            <input type="text" v-model="item.value" class="input-width-smallNew four" @keyup="keyboard($event, index,'four')" 
                            @keydown="expurgate($event, index,'four')" @focus="foucusInput('four')" maxlength="1">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index == 7">
                            <input type="text" v-model="item.value" class="input-width-smallNew four" @keyup="keyboard($event, index,'four')"
                            @keydown="expurgate($event, index,'four')" maxlength="1" @focus="foucusInput('four')" placeholder="新" style="background-color: #adf0bc">
                            
                        </el-form-item>
                    </span>
                </div>
                <img src="@/assets/images/car/del.png" v-if="statusList.four.delStatus == true" alt="" @click="clear('four')">
                <img src="@/assets/images/car/del-dis.png" v-else alt="" >
                <p class="error" v-if="statusList.four.isSubmit == false">车牌号格式不正确</p>
                <div class="province" v-show="statusList.four.showCity == true">
                    <span v-for="(item,index) in cityList" :key="index" @click="selecCity(item,'four')" :class="active == item ? 'active' : ''">{{ item }}</span>
                </div>
                <!-- 字母弹窗 -->
                <div class="province letter" v-show="statusList.four.showLetter == true">
                    <span v-for="(item,index) in letterList" :key="index" @click="selecLetter(item,'four')" :class="active == item ? 'active' : ''">{{ item }}</span>
                </div>
            </div>
            <div class="delContent">
                <div class="contant">
                    <span v-for="(item,index) in ruleForm.five" :key="index">
                        <el-form-item class="el-form-itemNew" v-if="index ==0">
                            <input type="text" v-model="item.value" class="input-width-smallNew five" @click="getCity('five',index)" 
                            @keyup="keyboard($event, index,'five')" @keydown="expurgate($event, index,'five')" maxlength="1" :readonly="true">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index == 1">
                            <input type="text" v-model="item.value" class="input-width-smallNew five" @click="getLetter('five',index)" 
                            @keyup="keyboard($event, index,'five')" @keydown="expurgate($event, index,'five')" maxlength="1" :readonly="true">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index !==0 && index !==1 && index !==7">
                            <input type="text" v-model="item.value" class="input-width-smallNew five" @keyup="keyboard($event, index,'five')" 
                            @keydown="expurgate($event, index,'five')" @focus="foucusInput('five')" maxlength="1">
                        </el-form-item>
                        <el-form-item class="el-form-itemNew" v-if="index == 7">
                            <input type="text" v-model="item.value" class="input-width-smallNew five" @keyup="keyboard($event, index,'five')"
                            @keydown="expurgate($event, index,'five')" maxlength="1" @focus="foucusInput('five')" placeholder="新" style="background-color: #adf0bc">
                        </el-form-item>
                    </span>
                </div>
                <img src="@/assets/images/car/del.png" v-if="statusList.five.delStatus == true" alt="" @click="clear('five')">
                <img src="@/assets/images/car/del-dis.png" v-else alt="" >
                <p class="error" v-if="statusList.five.isSubmit == false">车牌号格式不正确</p>
                <div class="province" v-show="statusList.five.showCity == true">
                    <span v-for="(item,index) in cityList" :key="index" @click="selecCity(item,'five')" :class="active == item ? 'active' : ''">{{ item }}</span>
                </div>
                <!-- 字母弹窗 -->
                <div class="province letter" v-show="statusList.five.showLetter == true">
                    <span v-for="(item,index) in letterList" :key="index" @click="selecLetter(item,'five')" :class="active == item ? 'active' : ''">{{ item }}</span>
                </div>
            </div>
        </el-form>
        <div class="flex-grow" @click="foucusInput()"></div>
        <DialogWrapper
            :dialogObj="DialogObj"
            @handleClose="handleUpdateClose"
        >
            <div
                slot="title"
                style="position: relative"
            >
                <div style="text-align: center">{{ DialogObj.title }}</div>
            </div>
            <div class="ruleContent">
                <p><span>第一位：</span>下拉选项框，内容为省简称，共三十二个：北京市(京)、天津市(津)、上海市(沪)、河北省(冀)、河南省(豫)、云南省(云)、辽宁省(辽)、黑龙江省(黑)、湖南省(湘)、安徽省(皖)、山东省(鲁)、新疆维吾尔(新)、江苏省(苏)、浙江省(浙)、江西省(赣)、湖北省(鄂)、广西壮族(桂)、甘肃省(甘)、山西省(晋）、内蒙古(蒙)、陕西省(陕)、吉林省(吉)、贵州省(贵)、广东省(粤)、青海省(青)、西藏(藏)、四川省(川)、宁夏回族(宁)、海南省(琼)、重庆市(渝)、福建省(闽)</p>
                <p><span>第二位：</span>下拉选项框，可模糊搜索，为大写字母，内容为省下市区的代表字母，其下拉选项框内容与第一位省简称有关联</p>
                <p><span>第三-七位：</span>内容为数字(0-9)、大写英文字母(A-Z,不包含0、1)组成，若车号编码与规则不符，则会提醒--车牌号格式不正确</p>
                <p><span>第八位：</span>新能源相较于普通车牌号多一位，最后一位可选填</p>
            </div>
        </DialogWrapper>

    </div>
</template>
<script>
import {
    // 业务组件
    DialogWrapper,
} from "common-local";
export default {
    components:{ DialogWrapper },
    props:{
        plateData: {
            type: Array,
            default: ()=>([])
        }
    },
    data(){
        return {
            ruleForm: {
                one: [ {value:''}, {value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''}],
                two: [ {value:''}, {value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''} ],
                three: [ {value:''}, {value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''} ],
                four: [ {value:''}, {value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''} ],
                five: [ {value:''}, {value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''} ]
            },
            statusList: {  //delStatus 是否展示删除图片，isSubmit：格式是否正确可提交， car：拼接后的车牌 showCity:省  showletter 市
                one: {delStatus:false,isSubmit: true,car:'',showCity:false,showLetter:false},
                three: {delStatus:false,isSubmit: true,car:'',showCity:false,showLetter:false},
                two: {delStatus:false,isSubmit: true,car:'',showCity:false,showLetter:false},
                four: {delStatus:false,isSubmit: true,car:'',showCity:false,showLetter:false},
                five: {delStatus:false,isSubmit: true,car:'',showCity:false,showLetter:false},
            },
            cityList:[
                '京','津','沪','冀','豫','云','辽','黑','湘','皖','鲁','新','苏','浙','赣','鄂','桂',
                '甘','晋','蒙','陕','吉','贵','粤','青','藏','川','宁','琼','渝','闽',
            ],
            letterList:['A','B','C','D','E','F','G','H','J','K','L','M','N','P','Q','R','S','T','U','V','W','X','Y','Z'],
            DialogObj: {
				title: "车牌号编码规则",
				dialogVisible: false,
				width: "560px",
			},
            active:'',
            nameList:['one','two','three','four','five']
        }
    },
    methods: {
        /**
         * @Description:  打开省市弹窗
         * @Author: hjm
         * @Date: 2024-09-05 10:48:26
         * @param {String} value  选中数据
         * @param {Number} index 下标
         */        
        getCity(value,index){
            this.foucusInput()
            if(this.ruleForm[value][0].value){
                this.active  = this.ruleForm[value][0].value;
            }else{
                this.active = ''
            }
            this.statusList[value].showCity = true;
        },
        /**
         * @Description: 选中省
         * @Author: hjm
         * @Date: 2024-09-05 11:23:57
         * @param {*} item 值
         * @param {*} value  选中数据
         */        
        selecCity(item,value){
            this.active = item;
            this.ruleForm[value][0].value = item;
            this.statusList[value].showCity = false;
            this.statusList[value].showLetter = false;
            this.validateFrom(value)
        },
        /**
         * @Description:  打开省市弹窗
         * @Author: hjm
         * @Date: 2024-09-05 10:48:26
         * @param {String} value  选中数据
         * @param {Number} index 下标
         */  
        getLetter(value,index){
            this.foucusInput()
            if(this.ruleForm[value][1].value){
                this.active  = this.ruleForm[value][1].value;
            }else{
                this.active = ''
            }
            this.statusList[value].showLetter = true;
        },
        /**
         * @Description: 选中市
         * @Author: hjm
         * @Date: 2024-09-05 11:23:57
         * @param {*} item 值
         * @param {*} value  选中数据
         */ 
        selecLetter(item,value){
            this.active = item;
            this.ruleForm[value][1].value = item;
            this.statusList[value].showLetter = false;
            this.statusList[value].showCity = false;
            this.validateFrom(value)
        },
        isUpperCaseAlphanumeric(str) {
            return /^[A-Z0-9]+$/.test(str);
        },
        /**
         * @Description: 光标下移/上移
         * @Author: hjm
         * @Date: 2024-09-05 09:13:48
         * @param { Object } e 事件
         * @param { Number } index  下标
         * @param { String } value  选中的名称
         */        
        keyboard(e, index,value) {
            var validate = this.isUpperCaseAlphanumeric(this.ruleForm[value][index].value)
            console.log(validate,'hjm');
            
            let domNode = document.getElementsByClassName(value),
                currInput = domNode[index],
                nextInput = domNode[index + 1],
                lastInput = domNode[index - 1];
                
                if (e.keyCode != 8) {
                    if(validate == false && index != 0){
                        this.ruleForm[value][index].value = ''
                        return;
                    }
                    if(!this.ruleForm[value][index].value){
                        return;
                    }
                    if (index < this.ruleForm[value].length - 1) {
                        nextInput.focus();
                    } else {
                        currInput.blur();
                    }
                } else {
                    if (index != 0) {
                        lastInput.focus();
                    }
                }
            this.validateFrom(value)
        },
        /**
         * @Description: 清楚当前input内容
         * @Author: hjm
         * @Date: 2024-09-05 09:12:20
         */
        // 键盘按下触发
        expurgate(e,index,value) {
            if(e.keyCode == 8){
                this.ruleForm[value][index].value = "";
            }
            this.statusList[value].car = ''
            for (let i = 0; i < this.ruleForm[value].length; i++) {
                this.statusList[value].car += this.ruleForm[value][i].value
            }
            
        },
        /**
         * @Description: 清除当条数据全部内容
         * @Author: hjm
         * @Date: 2024-09-05 09:12:56
         * @param {String} value  选中的数据名
         */        
        clear(value){
            for (let i = 0; i < this.ruleForm[value].length; i++) {
                this.ruleForm[value][i].value = '';
            };
            this.statusList[value].car = '';
            this.statusList[value].delStatus = false;
            this.statusList[value].isSubmit = true;
        },
        /**
         * @Description:  关闭弹窗
         * @Author: hjm
         * @Date: 2024-09-05 10:08:04
         */        
        handleUpdateClose() {
            this.DialogObj.dialogVisible = false;
		},
        /**
         * @Description: 验证数据
         * @Author: hjm
         * @Date: 2024-09-05 11:30:29
         * @param {*} value 名称
         */        
        validateFrom(value){
            this.statusList[value].car = ''
            for (let i = 0; i < this.ruleForm[value].length; i++) {
                this.statusList[value].car += this.ruleForm[value][i].value;
            };
            console.log(this.statusList[value].car.length);
            if(this.statusList[value].car.length >= 7){
                this.isValidatePlate(this.statusList[value].car, value)
            }
        },
        /**
         * @Description: 验证车牌规则 仅判断长度大于等于7
         * @Author: hjm
         * @Date: 2024-09-05 11:58:54
         * @param {String} value 值
         * @param {String} name 对应数组
         */        
        isValidatePlate(value,name){
            if(value.length >= 7) {
                this.statusList[name].isSubmit = true;
            }else{
                this.statusList[name].isSubmit = false;
                return;
            }
            // const reg = /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1})$/;
            // if(reg.test(value)){
            //     this.statusList[name].isSubmit = true;
            // }else{
            //     this.statusList[name].isSubmit = false;
            // }
        },
        validatesubmit(){
            for (let i = 0; i < this.nameList.length; i++) {
                if(this.statusList[this.nameList[i]].car !== ''){
                    console.log(this.statusList[this.nameList[i]].car)
                    this.isValidatePlate(this.statusList[this.nameList[i]].car, this.nameList[i])
                }
            }
            this.$emit('carNumber', this.statusList)
            
        },
        /**
         * @Description: 获取焦点
         * @Author: hjm
         * @Date: 2024-09-05 15:39:42
         * @param {String} value 对应数组
         */
        foucusInput(value){
            for (let i = 0; i < this.nameList.length; i++) {
                this.statusList[this.nameList[i]].showCity = false;
                this.statusList[this.nameList[i]].showLetter = false;
            }
            
        }
    },
    watch:{
        'plateData':{
            handler(){
                console.log(this.plateData,'plate')
                for (let j = 0; j < this.nameList.length; j++) {
                    for (let y = 0; y < this.ruleForm[this.nameList[j]].length; y++) {
                        this.ruleForm[this.nameList[j]][y].value = '';
                    }
                    this.statusList[this.nameList[j]].delStatus = false;
                    this.statusList[this.nameList[j]].isSubmit = true;
                    this.statusList[this.nameList[j]].car = '';
                }
                for (let i = 0; i < this.plateData.length; i++) {
                    let stringList = this.plateData[i].split('');
                    for (let index = 0; index < stringList.length; index++) {
                        this.ruleForm[this.nameList[i]][index].value = stringList[index];
                        this.statusList[this.nameList[i]].delStatus = true;
                        this.statusList[this.nameList[i]].isSubmit = true;
                        this.statusList[this.nameList[i]].car = this.plateData[i];
                    }
                    
                }
            },
            deep:true,
            immediate:true
        },
        'statusList.one.car':{
            handler(){
                if(this.statusList.one.car != ''){
                    this.statusList.one.delStatus = true;
                }else{
                    this.statusList.one.delStatus = false;
                    this.statusList.one.isSubmit = true;
                }
            },
            deep:true,
            immediate:true
        },
        'statusList.two.car':{
            handler(){
                if(this.statusList.two.car != ''){
                    this.statusList.two.delStatus = true;
                }else{
                    this.statusList.two.delStatus = false;
                    this.statusList.two.isSubmit = true;
                }
            },
            deep:true,
            immediate:true
        },
        'statusList.three.car':{
            handler(){
                if(this.statusList.three.car != ''){
                    this.statusList.three.delStatus = true;
                }else{
                    this.statusList.three.delStatus = false;
                    this.statusList.three.isSubmit = true;
                }
            },
            deep:true,
            immediate:true
        },
        'statusList.four.car':{
            handler(){
                if(this.statusList.four.car != ''){
                    this.statusList.four.delStatus = true;
                }else{
                    this.statusList.four.delStatus = false;
                    this.statusList.four.isSubmit = true;
                }
            },
            deep:true,
            immediate:true
        },
        'statusList.five.car':{
            handler(){
                if(this.statusList.five.car != ''){
                    this.statusList.five.delStatus = true;
                }else{
                    this.statusList.five.delStatus = false;
                    this.statusList.five.isSubmit = true;
                }
            },
            deep:true,
            immediate:true
        }
    },
}
</script>
<style lang="scss" scoped>
.carContent{
    display: flex;
    .ruleform{
        display: inline-block;
    }
    .flex-grow{
        flex-grow: 1;
    }
    .rule{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #3C7FFF;
        margin: 10px 0;
        cursor: pointer;
    }
}
.delContent{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
    position: relative;
    .province{
        width: 361px;
        height: 180px;
        background-image: url(('@/assets/images/car/bg.png'));
        background-size: 100;
        position: absolute;
        background-position: center,top;
        top: -175px;
        left: -62px;
        z-index: 1;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        padding: 7px 20px 22px;
        box-sizing: border-box;
        >span{
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            color: #2B3133;
            background: #F2F2F2;
            border-radius: 4px;
            margin: 0px 4px;
            cursor: pointer;
        }
        .active{
            background: #3C7FFF;
            color:#fff;
        }
    }
    .letter{
        left: -28px;
        padding: 15px 20px 20px;
    }
    .contant{
        background-color: #F5F5F5;
        padding: 3px;
        display: inline-block;
        border-radius: 4px;
        margin-right: 10px;
        display: flex;
        
    }
    >img{
        width: 24px;
    }
    .error{
        width: 100%;
        color: #F56C6C;
        font-size: 12px;
        height: 14px;
        line-height: 14px;
    }

}
.ruleContent{
    >p{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #808587;
        line-height: 24px;
        >span{
            color: #2B3133;
        }
        margin-bottom: 10px;
    }
}
.el-form-itemNew{
    margin-bottom: 0;
    position: relative;
}

.input-width-smallNew {
    width: 32px;
    border: 1px solid #DCDEE0;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    // padding: 0 8px;
    box-sizing: border-box;
    font-size: 14px;
    margin-right: 2px;
    background-color: #fff;
    text-align: center;
    outline: none;
    
}
</style>
